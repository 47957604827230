'use client'

import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import clsx from 'clsx'
import Image from 'next/image'
import { useEffect, useState } from 'react'
import { Container } from 'src/components/Container'
import screenshotComments from 'src/images/screenshots/comments.png'
import screenshotOnGitHub from 'src/images/screenshots/on-github.png'
import screenshotVersionControl from 'src/images/screenshots/version-control.png'

const features = [
    {
        title: 'Git based workflows ',
        description: `Circuitly's GitHub and GitLab integration lets your designs live and version alongside the rest of your work. Confidently commit, rebase and merge your work using Circuitly's visual differencing.`,
        image: screenshotVersionControl,
    },
    {
        title: 'Easily get feedback',
        description:
            'Comments, reactions, notifications and link sharing make it easy to get meaningful feedback with context.',
        image: screenshotComments,
    },
    {
        title: 'Maintain full control',
        description:
            'Circuitly edits files in the native formats of existing tools, storing them in GitHub, GitLab or self hosted git repositories, ensuring maximum control over your data.',
        image: screenshotOnGitHub,
    },
]

export function SecondaryFeatures() {
    const [tabOrientation, setTabOrientation] = useState<'horizontal' | 'vertical'>('horizontal')

    useEffect(() => {
        const lgMediaQuery = window.matchMedia('(min-width: 1024px)')

        function onMediaQueryChange({ matches }: { matches: boolean }) {
            setTabOrientation(matches ? 'vertical' : 'horizontal')
        }

        onMediaQueryChange(lgMediaQuery)
        lgMediaQuery.addEventListener('change', onMediaQueryChange)

        return () => {
            lgMediaQuery.removeEventListener('change', onMediaQueryChange)
        }
    }, [])

    return (
        <section aria-label="Features" className="relative overflow-hidden pb-28 pt-8 sm:py-8">
            <Container className="relative">
                <div className="max-w-4xl xl:max-w-none">
                    <h2 className="font-display tracking-tight text-4xl font-semibold text-gray-800 sm:text-4xl md:text-4xl">
                        Modern workflows, modern interface
                    </h2>
                </div>
                <TabGroup
                    className="mt-16 grid grid-cols-1 items-start gap-x-6 gap-y-2 pt-10 sm:gap-y-6 md:mt-10 lg:grid-cols-12 lg:pt-0"
                    vertical={tabOrientation === 'vertical'}
                >
                    {({ selectedIndex }) => (
                        <>
                            <div className="-mx-4 flex overflow-x-auto pb-4 sm:mx-0 overflow-visible sm:pb-0 lg:col-span-5">
                                <TabList className="relative z-10 flex gap-x-4 whitespace-nowrap px-4 sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1 lg:whitespace-normal">
                                    {features.map((feature, featureIndex) => (
                                        <div key={feature.title}>
                                            <div
                                                className={clsx(
                                                    'flex flex-col align-start group relative rounded-full px-4 py-1 lg:rounded-xl lg:p-6',
                                                    selectedIndex === featureIndex
                                                        ? 'bg-black lg:bg-gray-100 lg:ring-1 lg:ring-inset lg:ring-white/10'
                                                        : 'hover:bg-gray-50',
                                                )}
                                            >
                                                <Tab
                                                    className={clsx(
                                                        'flex flex-1 items-center font-display text-left text-lg ui-not-focus-visible:outline-none',
                                                        selectedIndex === featureIndex
                                                            ? 'text-white lg:text-black'
                                                            : 'text-gray-500 hover:text-black lg:text-black',
                                                    )}
                                                >
                                                    <div className="text-xl font-bold flex-1">{feature.title}</div>
                                                    {tabOrientation === 'vertical' && (
                                                        <>
                                                            {featureIndex === selectedIndex ? (
                                                                <svg
                                                                    width="16"
                                                                    height="17"
                                                                    viewBox="0 0 16 17"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <g id="Chevron_Up">
                                                                        <path
                                                                            id="Vector 2"
                                                                            d="M2 11.5L8 5.5L14 11.5"
                                                                            stroke="#89919D"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            ) : (
                                                                <svg
                                                                    width="16"
                                                                    height="16"
                                                                    viewBox="0 0 16 16"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <g id="Chevron_Down">
                                                                        <path
                                                                            id="Vector 2"
                                                                            d="M14 5L8 11L2 5"
                                                                            stroke="#89919D"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            )}
                                                        </>
                                                    )}
                                                </Tab>
                                                {selectedIndex === featureIndex && (
                                                    <p className={'mt-2 hidden text-lg lg:block'}>
                                                        {feature.description}
                                                    </p>
                                                )}
                                            </div>
                                            {featureIndex !== selectedIndex &&
                                                featureIndex + 1 !== selectedIndex &&
                                                featureIndex < features.length - 1 &&
                                                tabOrientation === 'vertical' && <hr />}
                                        </div>
                                    ))}
                                </TabList>
                            </div>
                            <TabPanels className="lg:col-span-7">
                                {features.map(feature => (
                                    <TabPanel key={feature.title} unmount={false}>
                                        <div className="relative sm:px-6 lg:hidden">
                                            <div className="absolute -inset-x-4 bottom-[-4.25rem] top-[-6.5rem] bg-white/10 ring-1 ring-inset ring-white/10 sm:inset-x-0 sm:rounded-t-xl" />
                                            <p className="relative mx-auto max-w-2xl px-3 text-base">
                                                {feature.description}
                                            </p>
                                        </div>
                                        <div className="mt-10 overflow-hidden rounded-xl bg-slate-50 shadow-xl shadow-blue-900/20 max-lg:w-auto lg:mt-0 max-lg:max-w-[80%]">
                                            <Image src={feature.image} alt="" />
                                        </div>
                                    </TabPanel>
                                ))}
                            </TabPanels>
                        </>
                    )}
                </TabGroup>
            </Container>
        </section>
    )
}
