'use client'

import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import clsx from 'clsx'
import Image, { type ImageProps } from 'next/image'
import { Container } from 'src/components/Container'
import screenshotBoard from 'src/images/screenshots/board.png'
import screenshotWorkspaces from 'src/images/screenshots/from-github.png'
import screenshotSchematic from 'src/images/screenshots/schematic.png'

type Feature = {
    name: React.ReactNode
    summary: string
    description: string
    image: ImageProps['src']
    icon: React.ComponentType
}

const features: Array<Feature> = [
    {
        name: 'Workspaces',
        summary: 'TBD',
        description: 'TBD',
        image: screenshotWorkspaces,
        icon: function ReportingIcon() {
            return (
                <>
                    <rect y="0.5" width="9" height="9" />
                    <rect y="12.5" width="9" height="9" />
                    <rect x="12" y="0.5" width="9" height="9" />
                    <rect x="12" y="12.5" width="9" height="9" />
                </>
            )
        },
    },
    {
        name: 'Schematics',
        summary: 'TBD2',
        description: 'TBD',
        image: screenshotSchematic,
        icon: function InventoryIcon() {
            return (
                <>
                    <g clipPath="url(#clip0_613_22291)">
                        <mask id="path-1-inside-1_613_22291">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0.5 4.5C0.5 3.67157 1.17157 3 2 3H23C23.8284 3 24.5 3.67157 24.5 4.5V13.5H23H17H15.5H12.5H11V15V19.5V21L2 21C1.17157 21 0.5 20.3284 0.5 19.5V4.5ZM12.5 15H15.5V16.5H12.5V15ZM17 15V16.5H18.5H20H23V15H17ZM18.5 18H17H15.5H12.5V19.5H18.5V18ZM20 19.5H23V18H20V19.5Z"
                            />
                        </mask>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.5 4.5C0.5 3.67157 1.17157 3 2 3H23C23.8284 3 24.5 3.67157 24.5 4.5V13.5H23H17H15.5H12.5H11V15V19.5V21L2 21C1.17157 21 0.5 20.3284 0.5 19.5V4.5ZM12.5 15H15.5V16.5H12.5V15ZM17 15V16.5H18.5H20H23V15H17ZM18.5 18H17H15.5H12.5V19.5H18.5V18ZM20 19.5H23V18H20V19.5Z"
                        />
                        <path
                            d="M24.5 13.5V15H26V13.5H24.5ZM11 13.5V12H9.5V13.5H11ZM11 21V22.5H12.5V21H11ZM2 21L2 19.5H2V21ZM15.5 15H17V13.5H15.5V15ZM12.5 15V13.5H11V15H12.5ZM15.5 16.5V18H17V16.5H15.5ZM12.5 16.5H11V18H12.5V16.5ZM17 16.5H15.5V18H17V16.5ZM17 15V13.5H15.5V15H17ZM23 16.5V18H24.5V16.5H23ZM23 15H24.5V13.5H23V15ZM18.5 18H20V16.5H18.5V18ZM12.5 18V16.5H11V18H12.5ZM12.5 19.5H11V21H12.5V19.5ZM18.5 19.5V21H20V19.5H18.5ZM20 19.5H18.5V21H20V19.5ZM23 19.5V21H24.5V19.5H23ZM23 18H24.5V16.5H23V18ZM20 18V16.5H18.5V18H20ZM2 1.5C0.343146 1.5 -1 2.84314 -1 4.5H2V4.5V1.5ZM23 1.5H2V4.5H23V1.5ZM26 4.5C26 2.84315 24.6569 1.5 23 1.5V4.5H26ZM26 13.5V4.5H23V13.5H26ZM23 15H24.5V12H23V15ZM17 15H23V12H17V15ZM15.5 15H17V12H15.5V15ZM12.5 15H15.5V12H12.5V15ZM11 15H12.5V12H11V15ZM12.5 15V13.5H9.5V15H12.5ZM12.5 19.5V15H9.5V19.5H12.5ZM12.5 21V19.5H9.5V21H12.5ZM2 22.5L11 22.5V19.5L2 19.5L2 22.5ZM-1 19.5C-1 21.1569 0.343145 22.5 2 22.5V19.5H-1ZM-1 4.5V19.5H2V4.5H-1ZM15.5 13.5H12.5V16.5H15.5V13.5ZM17 16.5V15H14V16.5H17ZM12.5 18H15.5V15H12.5V18ZM11 15V16.5H14V15H11ZM18.5 16.5V15H15.5V16.5H18.5ZM18.5 15H17V18H18.5V15ZM18.5 18H20V15H18.5V18ZM23 15H20V18H23V15ZM21.5 15V16.5H24.5V15H21.5ZM17 16.5H23V13.5H17V16.5ZM18.5 16.5H17V19.5H18.5V16.5ZM17 16.5H15.5V19.5H17V16.5ZM15.5 16.5H12.5V19.5H15.5V16.5ZM11 18V19.5H14V18H11ZM12.5 21H18.5V18H12.5V21ZM20 19.5V18H17V19.5H20ZM20 21H23V18H20V21ZM24.5 19.5V18H21.5V19.5H24.5ZM23 16.5H20V19.5H23V16.5ZM18.5 18V19.5H21.5V18H18.5Z"
                            mask="url(#path-1-inside-1_613_22291)"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_613_22291">
                            <rect width="24" height="24" transform="translate(0.5)" />
                        </clipPath>
                    </defs>
                </>
            )
        },
    },
    {
        name: 'Boards',
        summary: 'TBD3',
        description: 'TBD',
        image: screenshotBoard,
        icon: function ContactsIcon() {
            return (
                <>
                    <g clipPath="url(#clip0_613_22311)">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.5 1.04998C0.5 0.470094 0.970094 0 1.54998 0H2.75V6V6.75H3.5H8.09451C8.42755 8.04392 9.60212 9 11 9C12.6569 9 14 7.65685 14 6C14 4.34315 12.6569 3 11 3C9.60212 3 8.42755 3.95608 8.09451 5.25H4.25V0H23C23.8284 0 24.5 0.671573 24.5 1.5V16.5C24.5 17.3284 23.8284 18 23 18H15.8407C15.6647 18 15.5219 18.1428 15.5219 18.3188V21.4312C15.5219 21.6072 15.6647 21.75 15.8407 21.75H23.375C23.9963 21.75 24.5 22.2537 24.5 22.875C24.5 23.4963 23.9963 24 23.375 24H2C1.17157 24 0.5 23.3284 0.5 22.5V15.75L8.09451 15.75C8.42755 17.0439 9.60212 18 11 18C12.6569 18 14 16.6569 14 15C14 13.3431 12.6569 12 11 12C9.60212 12 8.42754 12.9561 8.09451 14.25L0.5 14.25V13.3501V7.76265V1.04998ZM9.5 15C9.5 15.8284 10.1716 16.5 11 16.5C11.8284 16.5 12.5 15.8284 12.5 15C12.5 14.1716 11.8284 13.5 11 13.5C10.1716 13.5 9.5 14.1716 9.5 15ZM11 7.5C10.1716 7.5 9.5 6.82843 9.5 6C9.5 5.17157 10.1716 4.5 11 4.5C11.8284 4.5 12.5 5.17157 12.5 6C12.5 6.82843 11.8284 7.5 11 7.5ZM21.9312 14.5688H19.3058C19.1298 14.5688 18.987 14.426 18.987 14.25V2.90279C18.987 2.7268 19.1298 2.58397 19.3058 2.58397H21.9312C22.1072 2.58397 22.25 2.7268 22.25 2.90279V14.25C22.25 14.426 22.1076 14.5688 21.9312 14.5688Z"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_613_22311">
                            <rect width="24" height="24" transform="translate(0.5)" />
                        </clipPath>
                    </defs>
                </>
            )
        },
    },
]

function Feature({
    feature,
    isActive,
    className,
    ...props
}: React.ComponentPropsWithoutRef<'div'> & {
    feature: Feature
    isActive: boolean
}) {
    return (
        <div
            className={clsx(
                className,
                !isActive && 'opacity-75 hover:opacity-100',
                'p-3 rounded-lg',
                isActive && 'bg-primary-100',
            )}
            {...props}
        >
            <div className="flex justify-center">
                <div
                    className={clsx(
                        'w-6',
                        // isActive ? 'bg-primary-500' : 'bg-neutral-500',
                    )}
                >
                    <svg
                        aria-hidden="true"
                        className="h-6"
                        fill={isActive ? 'rgba(51, 141, 229, 1)' : 'rgba(137, 145, 157, 1)'}
                    >
                        <feature.icon />
                    </svg>
                </div>
            </div>
            <p
                className={clsx(
                    'mt-3 text-md font-bold text-center',
                    isActive ? 'text-primary-500' : 'text-neutral-500',
                )}
            >
                {feature.name}
            </p>
        </div>
    )
}

function FeaturesDesktop() {
    return (
        <TabGroup className="overflow-x-hidden">
            {({ selectedIndex }) => (
                <>
                    <TabList className="lg:mx-[25%] grid grid-cols-3 gap-x-8">
                        {features.map((feature, featureIndex) => (
                            <Feature
                                key={feature.summary}
                                feature={{
                                    ...feature,
                                    name: (
                                        <Tab className="ui-not-focus-visible:outline-none">
                                            <span className="absolute inset-0" />
                                            {feature.name}
                                        </Tab>
                                    ),
                                }}
                                isActive={featureIndex === selectedIndex}
                                className="relative"
                            />
                        ))}
                    </TabList>
                    <TabPanels className="relative mt-10 overflow-hidden rounded-4xl bg-slate-200 px-8 py-8 xl:px-16">
                        <div className="-mx-5 flex">
                            {features.map((feature, featureIndex) => (
                                <TabPanel
                                    static
                                    key={feature.summary}
                                    className={clsx(
                                        'px-5 transition duration-500 ease-in-out ui-not-focus-visible:outline-none',
                                        featureIndex !== selectedIndex && 'opacity-60',
                                    )}
                                    style={{ transform: `translateX(-${selectedIndex * 100}%)` }}
                                    aria-hidden={featureIndex !== selectedIndex}
                                >
                                    <div className="w-[80vw] max-w-[52.57rem] overflow-hidden rounded-xl ring-1 ring-slate-500/10 bg-featureScreenshot">
                                        <div className="p-8">
                                            <Image className="overflow-hidden rounded-lg" src={feature.image} alt="" />
                                        </div>
                                    </div>
                                </TabPanel>
                            ))}
                        </div>
                        <div className="pointer-events-none absolute inset-0 rounded-4xl ring-1 ring-inset ring-slate-900/10" />
                    </TabPanels>
                </>
            )}
        </TabGroup>
    )
}

export function PrimaryFeatures() {
    return (
        <section id="features" aria-label="Features" className="pb-14 pt-16 sm:pb-20 sm:pt-16 lg:pb-30 bg-neutral-50">
            <Container>
                <FeaturesDesktop />
            </Container>
        </section>
    )
}
